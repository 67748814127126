import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Work } from 'src/types';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingTop: 0,
  paddingBottom: 48,

  [theme.breakpoints.up('md')]: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  '& img': {
    width: 100,

    [theme.breakpoints.up('md')]: {
      width: 180,
    },
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  margin: 0,
  fontWeight: 700,

  [theme.breakpoints.up('md')]: {
    fontSize: 38,
  },
})) satisfies typeof Typography;

const Description = styled(Typography)({
  fontSize: 16,
  margin: 0,
}) satisfies typeof Typography;

interface Props {
  item: Work;
  idx: number;
}

const WorkItem: FC<Props> = ({ item, idx }) => (
  <Wrapper>
    <img src={item.icon} alt="hyperconnect company" />
    <div>
      <Description variant="body1">{`0${idx + 1}`}</Description>
      <Title variant="h3">{item.title}</Title>
      <Description variant="body1">{item.description}</Description>
    </div>
  </Wrapper>
);

export default WorkItem;
