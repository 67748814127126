import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { Perk } from 'src/types';

interface StyleProps {
  width: number | string;
  height?: number | string;
}

interface Props extends StyleProps {
  item: Perk;
}

const CardGrid = styled(Grid)<StyleProps>(({ theme, width }) => ({
  paddingLeft: 4,
  paddingRight: 4,
  width: width || 312,
  [theme.breakpoints.up('md')]: {
    width: width || 638,
  },
}));

const PerksCard = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  boxSizing: 'border-box',
  height: 190,
  borderRadius: 40,
  boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  [theme.breakpoints.up('md')]: {
    height: 264,
    padding: theme.spacing(0, 8),
  },
}));

const PerksTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  marginBottom: 8,
  [theme.breakpoints.up('md')]: {
    lineHeight: '40px',
    fontSize: 34,
    marginBottom: 0,
  },
})) as typeof Typography;

const PerksDesc = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  [theme.breakpoints.up('md')]: {
    fontSize: 20,
    lineHeight: '32px',
  },
})) as typeof Typography;

const PerksDescWrap = styled(Grid)({
  marginTop: 20,
});

const Card: FC<Props> = ({ width, item }) => (
  <CardGrid item width={width}>
    <PerksCard key={item.title}>
      <PerksTitle component="h4">{item.title}</PerksTitle>
      <PerksDescWrap>
        {item.desc.split('\n').map((desc: string, idx: number) => (
          <PerksDesc key={idx} component="p">
            {desc}
          </PerksDesc>
        ))}
      </PerksDescWrap>
    </PerksCard>
  </CardGrid>
);

export default Card;
