import { useEffect, useMemo, useState } from 'react';

export default function useTyping(throttleTime = 150) {
  const words = ['Career', 'Dream', 'Life', 'World', 'Goal'];

  const [typingTime, setTypingTime] = useState(0);
  const [activeTextIndex, setActiveTextIndex] = useState(0);
  const textLength = words[activeTextIndex].length;
  useEffect(() => {
    const interval = setInterval(() => {
      setTypingTime(time => {
        if (time >= textLength * 2 + 6) {
          setActiveTextIndex(
            activeTextIndex < (words.length - 1) ? activeTextIndex + 1 : 0
          );
          return 0;
        }
        return time + 1;
      });
    }, throttleTime);
    return () => clearInterval(interval);
  }, [activeTextIndex]);

  const showText = useMemo(() => {
    const length =
      typingTime < textLength + 6
        ? typingTime
        : words[activeTextIndex].length * 2 - (typingTime - 6);
    return words[activeTextIndex].slice(0, length);
  }, [typingTime, activeTextIndex]);

  return showText;
}
