import React, { FC, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import Layout from '../template/layout';
import SEO from '../components/SEO';
import {
  WORK,
  PERKS_WORK_LIFE_BALANCE,
  PERKS_PROFESSIONAL_DEVELOPMENT,
  PERKS_HEALTH_AND_WELLNESS,
  PERKS_EMPLOYEE_PERKS,
} from '../constants';
import Cards from '../components/Cards';
import CardsXs from '../components/CardsXs';
import WorkItem from '../components/WorkItem';
import { Work } from 'src/types';
import { keyframes, useMediaQuery } from '@mui/material';
import useTyping from 'src/hooks/useTyping';

const BottomArrow = keyframes`
  0% {
  transform: translateY(-0%) translateX(-50%);;
  
  }
  50% {
  transform: translateY(-30%) translateX(-50%);;
  }
  100% {
  transform: translateY(0%) translateX(-50%);;
  }
`;
const Section = styled('section')(({ theme }) => ({
  paddingTop: 24,
  paddingBottom: 24,
  overflow: 'hidden',

  [theme.breakpoints.up('md')]: {
    paddingTop: 100,
    paddingBottom: 100,
  },
}));

const SectionHeading2 = styled(Typography)(({ theme }) => ({
  lineHeight: 1.5,
  fontWeight: 600,
  fontSize: 28,
  marginBottom: 16,

  [theme.breakpoints.up('md')]: {
    lineHeight: 1,
    fontSize: 60,
    marginBottom: 24,
  },
})) satisfies typeof Typography;

const Paragraph = styled(Typography)(({ theme }) => ({
  fontSize: 16,

  [theme.breakpoints.up('md')]: {
    fontSize: 20,
    marginBottom: 24,
  },
})) as typeof Typography;

const PerksExplain = styled(Typography)({
  marginTop: 32,
}) as typeof Typography;

const Video = styled('video')({
  zIndex: 0,
  width: '100vw',
  height: '100vh',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  filter: 'brightness(0.5)',
  backgroundImage: 'url("/backgrounds/videoCapture.png")',
  backgroundSize: 'cover',
});

const ScrollButton = styled('div')`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 16px;
  animation: ${BottomArrow} 1s infinite;
`;

const MainSection = styled('div')`
  overflow: hidden;
  height: 100vh;
  width: 100%;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const VisionRow = styled('div')(({ theme }) => ({
  fontFamily: [
    'Poppins',
    'Pretendard',
    'Noto Sans KR',
    'system-ui',
    'sans-serif',
  ].join(','),
  display: 'flex',
  fontSize: 64,
  flexWrap: 'wrap',
  color: '#ffffff',
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    display: 'block',
    fontSize: 42,
    fontWeight: 700,
  },
}));

const WhiteText = styled('span')`
  color: #ffffff;
`;

const VideoContainer = styled('div')`
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const ConnectText = styled('span')(({ theme }) => ({
  margin: '0 8px',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    width: '100%',
  },
}));

const TypingRow = styled('div')(({ theme }) => ({
  display: 'flex',
  lineHeight: '96px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    width: '100%',
    lineHeight: '56px',
    height: '56px',
    alignItems: 'center',
  },
}));

const Typing = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '96px',
  marginRight: '8px',
}));

const TypingText = styled('span')(({ theme }) => ({
  fontWeight: '700',
  color: '#00dd99',
  fontSize: 64,
  marginLeft: '8px',
  [theme.breakpoints.down('md')]: {
    fontSize: 42,
  },
}));

const Cursor = styled('div')(({ theme }) => ({
  borderRight: ' 2px solid #1CD092',
  height: '60px',
  display: 'inline-block',
  [theme.breakpoints.down('md')]: {
    height: 32,
  },
}));

const DownButton = styled('img')(({ theme }) => ({
  width: 48,
  height: 48,
  [theme.breakpoints.down('md')]: {
    height: 32,
    width: 32,
  },
}));

const videoURL =
  'https://career-static.hyperconnect.com/static/about_%E1%84%86%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC/hyperconnect_1080p.mov';

const IndexPage: FC = () => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const typingText = useTyping();
  const section2Ref = useRef(null);

  return (
    <Layout>
      <Section
        style={{
          backgroundSize: 'cover',
          height: 'calc(100vh - 200px)',
        }}>
        <VideoContainer>
          <Video autoPlay muted loop playsInline>
            <source src={videoURL} type="video/mp4" />
            해당 브라우저는 비디오 기능을 제공하지 않습니다.
          </Video>
        </VideoContainer>
        <MainSection>
          <VisionRow>
            <ConnectText>Connect your</ConnectText>
            <TypingRow>
              <WhiteText>{' {'}</WhiteText>
              <Typing>
                <TypingText>{typingText}</TypingText>
                <Cursor />
              </Typing>
              <WhiteText>{'} '}</WhiteText>
            </TypingRow>
            <ConnectText> with us!</ConnectText>
          </VisionRow>
        </MainSection>
        <ScrollButton
          onClick={() =>
            section2Ref.current.scrollIntoView({
              behavior: 'smooth',
            })
          }>
          <DownButton src="/icons/icChevronDown.svg" />
        </ScrollButton>
      </Section>
      <Section ref={section2Ref}>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <StaticImage
                src="../images/indexPage/img-about-azar.png"
                alt="hyperconnect app screen"
                layout="constrained"
                width={600}
              />
            </Grid>
            <Grid container item xs={12} sm={6} alignItems="center">
              <div>
                <SectionHeading2 sx={{ fontWeight: 400 }}>
                  ABOUT
                  <img src="/logos/CI.svg" style={{ marginTop: 4 }} />
                </SectionHeading2>
                <Typography variant="body1">
                  2014년 설립된 하이퍼커넥트는 비디오 커뮤니케이션(WebRTC)과
                  인공지능(AI) 분야에서 세계 수준의 실력과 경험을 보유하고 있는
                  글로벌 영상 기술 기업입니다. 전 세계 사람들을 서로 연결하여
                  사회적·문화적 가치를 창출한다는 미션 아래, 세상을 연결하는
                  다양한 비디오 및 인공지능 기술 기반의 서비스들을 만들어가고
                  있습니다.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section
        style={{
          backgroundImage: 'url("/backgrounds/img-bg-type-02.svg")',
          backgroundSize: 'cover',
        }}>
        <Container>
          <SectionHeading2 variant="h2">
            <br />
            HOW WE WORK
            {!mdUp && <br />}
          </SectionHeading2>
          <Grid container>
            {WORK.map((item: Work, idx: number) => {
              return (
                <Grid item xs={12} sm={6} key={idx}>
                  <WorkItem item={item} idx={idx} />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <div>
            <SectionHeading2 variant="h2">OFFICE LIFE</SectionHeading2>
            <Paragraph variant="body1">
              하이퍼커넥트는 개인의 삶이 만족스러워야 회사에서도 만족감을 찾을
              수 있다고 믿습니다. <br />
              기업문화와 다양한 복지제도를 통해 개인의 생활 문제들을 해결하고자
              합니다. 당신의 건강과 삶, 그리고 행복한 회사생활을 위해
              노력합니다.
            </Paragraph>
          </div>
          {smUp ? (
            <>
              <Cards
                perks={PERKS_WORK_LIFE_BALANCE}
                title={'Work Life Balance'}
                isSingleRow
              />
              <Cards
                perks={PERKS_PROFESSIONAL_DEVELOPMENT}
                title={'Professional Development'}
              />
              <Cards
                perks={PERKS_HEALTH_AND_WELLNESS}
                title={'Health & Wellness'}
                dividerThreshold={3}
              />
              <Cards perks={PERKS_EMPLOYEE_PERKS} title={'Employee Perks'} />
            </>
          ) : (
            <>
              <CardsXs
                perks={PERKS_WORK_LIFE_BALANCE}
                title={'Work Life Balance'}
              />
              <CardsXs
                perks={PERKS_PROFESSIONAL_DEVELOPMENT}
                title={'Professional Development'}
              />
              <CardsXs
                perks={PERKS_HEALTH_AND_WELLNESS}
                title={'Health & Wellness'}
              />
              <CardsXs perks={PERKS_EMPLOYEE_PERKS} title={'Employee Perks'} />
            </>
          )}
          <PerksExplain component="p">
            * 일부 항목은 근속기간 등 별도 요건을 충족해야 합니다.
          </PerksExplain>
        </Container>
      </Section>
    </Layout>
  );
};

export default IndexPage;


export const Head = () =>{
  return <SEO title={'about'} />;
}