import React, { useRef, useState, useEffect, FC } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import throttle from 'lodash.throttle';

import { Perk } from 'src/types';
import Card from '../Card';

interface Props {
  perks: Perk[];
  title: string;
  isSingleRow?: boolean;
  /**
   * @description 이 값이 설정되어 있으면 해당 index를 기준으로 줄을 나눕니다.
   */
  dividerThreshold?: number;
}

const PerksArticle = styled('article')(({ theme }) => ({
  height: 758,

  [theme.breakpoints.down('md')]: {
    height: 558,
  },
}));

const PerksSlider = styled(Grid)({
  position: 'absolute',
  width: '100vw',
  userSelect: 'none',
  overflow: 'hidden',
  left: 0,
  paddingBottom: 48,
});

const PerksTitle = styled(Grid)({
  marginTop: 48,
});

const LifeHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 24,

  [theme.breakpoints.up('md')]: {
    fontSize: 34,
  },
})) satisfies typeof Typography;

const PerksDrager = styled(Grid)({
  userDrag: 'none',
  transition: 'transform 0.4s ease',
});

const PerksWrapper = styled(Grid)(({ theme }) => ({
  paddingTop: 16,
  width: 'max-content',

  [theme.breakpoints.up('md')]: {
    paddingTop: 40,
  },

  '& + &': {
    paddingTop: 20,
  },
}));

const PerksBlur = styled(Grid)({
  right: 0,
  top: 98,
  width: 300,
  height: 'calc( 100% - 50px )',
  position: 'absolute',
  background:
    'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);',
});

const Cards: FC<Props> = ({
  perks,
  title,
  isSingleRow = false,
  dividerThreshold = Math.floor(perks.length / 2),
}) => {
  const ref = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [elementX, setElementX] = useState(0);
  const [transformX, setTransformX] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [currentCard, setCurrentCard] = useState(0);

  const isNextAble = currentCard + 1 < perks.length / 2;
  const isPrevAble = currentCard > 0;

  const handleDrag = throttle(e => {
    if (isDragging) {
      setTransformX(e.clientX - elementX);
      ref.current.style.transform = `translate(${e.clientX - elementX}px, 0px)`;
    }
  }, 30);

  const handleDragStart = e => {
    ref.current.style.transition = 'transform 0.05s ease';
    setIsDragging(true);

    setElementX(e.clientX - transformX);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    ref.current.style.transition = null;

    isDragging &&
      setTimeout(() => {
        if (cardWidth / 4 < transformX + currentCard * cardWidth) {
          handlePrevCard();
        } else if (-cardWidth / 4 > transformX + currentCard * cardWidth) {
          handleNextCard();
        } else {
          handleUnchangeCard();
        }
      }, 30);
  };

  const handleNextCard = () => {
    isNextAble
      ? setCurrentCard(current => {
          const next = current + 1;
          ref.current.style.transform = `translate(${
            -1 * next * cardWidth
          }px,0px)`;
          setTransformX(-next * cardWidth);
          return next;
        })
      : handleUnchangeCard();
  };

  const handlePrevCard = () => {
    isPrevAble
      ? setCurrentCard(current => {
          const prev = current - 1;
          ref.current.style.transform = `translate(${-prev * cardWidth}px,0px)`;
          setTransformX(-prev * cardWidth);
          return prev;
        })
      : handleUnchangeCard();
  };

  const handleUnchangeCard = () => {
    setTransformX(-currentCard * cardWidth);
    ref.current.style.transform = `translate(${
      -currentCard * cardWidth
    }px,0px)`;
  };

  useEffect(() => {
    setCardWidth(ref.current.clientWidth / 2);
  }, [ref]);

  useEffect(() => {
    ref.current.addEventListener('mousemove', handleDrag);
    return () => {
      ref.current?.removeEventListener('mousemove', handleDrag);
    };
  }, [ref.current, isDragging, handleDrag]);

  return (
    <PerksArticle
      sx={{
        height: isSingleRow ? 454 : 758,
        // FIXME: 위쪽 styled component에서는 md 기준으로 잘랐는데 sx props에서는 theme을 사용할 수 없어서 직접 값을 넣어줌. 추후 수정 필요
        '@media (max-width: 900px)': {
          height: isSingleRow ? 324 : 558,
        },
      }}>
      <PerksSlider>
        <Container>
          <PerksTitle container>
            <Box flexGrow={1}>
              <LifeHeading variant="h3">{title}</LifeHeading>
            </Box>
            <Box>
              <Grid container>
                <Grid item onClick={handlePrevCard}>
                  <img
                    src="/icons/btn-arrow-left.svg"
                    alt="menu button"
                    style={{ opacity: !isPrevAble && '0.1' }}
                  />
                </Grid>
                <Grid item onClick={handleNextCard}>
                  <img
                    src="/icons/btn-arrow-right.svg"
                    alt="menu button"
                    style={{ opacity: !isNextAble && '0.1' }}
                  />
                </Grid>
              </Grid>
            </Box>
          </PerksTitle>
          <PerksDrager
            ref={ref}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onMouseLeave={handleDragEnd}>
            {isSingleRow ? (
              <PerksWrapper container spacing={5}>
                {perks.map((item: Perk, idx: number) => (
                  <Card key={idx} item={item} width={cardWidth} height={300} />
                ))}
              </PerksWrapper>
            ) : (
              <>
                <PerksWrapper container spacing={5}>
                  {perks
                    .slice(0, dividerThreshold)
                    .map((item: Perk, idx: number) => (
                      <Card
                        key={idx}
                        item={item}
                        width={cardWidth}
                        height={300}
                      />
                    ))}
                </PerksWrapper>
                <PerksWrapper
                  container
                  spacing={5}
                  style={{ marginLeft: cardWidth / 2 }}>
                  {perks
                    .slice(dividerThreshold)
                    .map((item: Perk, idx: number) => (
                      <Card
                        key={idx}
                        item={item}
                        width={cardWidth}
                        height={300}
                      />
                    ))}
                </PerksWrapper>
              </>
            )}
          </PerksDrager>
        </Container>
        <PerksBlur />
      </PerksSlider>
    </PerksArticle>
  );
};

export default Cards;
