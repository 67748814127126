import React, { useRef, useState, useEffect, FC } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Card from '../Card';
import { Perk } from 'src/types';

interface Props {
  perks: Perk[];
  title: string;
}

const PerksArticle = styled('article')({
  height: 278,
});

const PerksSlider = styled(Grid)({
  overflow: 'hidden',
  paddingBottom: 20,
  position: 'absolute',
  width: '100vw',
  userSelect: 'none',
  left: 0,
});

const PerksTitle = styled(Grid)({
  marginTop: 48,
});

const LifeHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 24,

  [theme.breakpoints.up('md')]: {
    fontSize: 34,
  },
})) satisfies typeof Typography;

const PerksDrager = styled(Grid)({
  userDrag: 'none',
  transition: 'transform 0.4s ease',
});

const PerksWrapper = styled(Grid)(({ theme }) => ({
  paddingTop: 16,
  width: 'max-content',

  [theme.breakpoints.up('md')]: {
    paddingTop: 40,
  },
}));

const CardsXs: FC<Props> = ({ perks, title }) => {
  const ref = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [elementX, setElementX] = useState(0);
  const [transformX, setTransformX] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [currentCard, setCurrentCard] = useState(0);

  const isNextAble = currentCard + 1 < perks.length;
  const isPrevAble = currentCard > 0;

  const handleDrag = e => {
    if (isDragging) {
      setTransformX(e.changedTouches[0].clientX - elementX);
      ref.current.style.transform = `translate(${
        e.changedTouches[0].clientX - elementX
      }px, 0px)`;
    }
  };

  const handleDragStart = e => {
    ref.current.style.transition = 'transform 0.1s ease';
    setIsDragging(true);

    setElementX(e.changedTouches[0].clientX - transformX);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    ref.current.style.transition = null;

    isDragging &&
      setTimeout(() => {
        if (cardWidth / 4 < transformX + currentCard * cardWidth) {
          handlePrevCard();
        } else if (-cardWidth / 4 > transformX + currentCard * cardWidth) {
          handleNextCard();
        } else {
          handleUnchangeCard();
        }
      }, 100);
  };

  const handleNextCard = () => {
    isNextAble
      ? setCurrentCard(current => {
          const next = current + 1;
          ref.current.style.transform = `translate(${
            -1 * next * cardWidth
          }px,0px)`;
          setTransformX(-next * cardWidth);
          return next;
        })
      : handleReturnCard();
  };

  const handlePrevCard = () => {
    isPrevAble
      ? setCurrentCard(current => {
          const prev = current - 1;
          ref.current.style.transform = `translate(${-prev * cardWidth}px,0px)`;
          setTransformX(-prev * cardWidth);
          return prev;
        })
      : handleUnchangeCard();
  };

  const handleUnchangeCard = () => {
    setTransformX(-currentCard * cardWidth);
    ref.current.style.transform = `translate(${
      -currentCard * cardWidth
    }px,0px)`;
  };

  const handleReturnCard = () => {
    setTransformX(0);
    setCurrentCard(0);
    ref.current.style.transform = `translate(0px,0px)`;
  };

  useEffect(() => {
    setCardWidth(ref.current.clientWidth + 12);
  }, [ref]);

  useEffect(() => {
    ref.current.addEventListener('touchmove', handleDrag);
    return () => {
      ref.current?.removeEventListener('touchmove', handleDrag);
    };
  }, [ref, isDragging, handleDrag]);

  useEffect(() => {
    const infinity = setInterval(() => {
      handleNextCard();
    }, 3000);
    return () => {
      clearInterval(infinity);
    };
  }, [handleNextCard]);

  return (
    <PerksArticle>
      <PerksSlider>
        <Container>
          <PerksTitle container>
            <Box flexGrow={1}>
              <LifeHeading variant="h3">{title}</LifeHeading>
            </Box>
            <Box></Box>
          </PerksTitle>
          <PerksDrager
            ref={ref}
            onTouchStart={handleDragStart}
            onTouchEnd={handleDragEnd}
          >
            <PerksWrapper container spacing={2}>
              {perks.map((item: Perk, idx: number) => (
                <Card key={idx} item={item} width={cardWidth} height={300} />
              ))}
            </PerksWrapper>
          </PerksDrager>
        </Container>
      </PerksSlider>
    </PerksArticle>
  );
};

export default CardsXs;
